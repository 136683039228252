import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [0];

export const dictionary = {
		"/": [4],
		"/about": [5],
		"/badge": [6],
		"/ecosystem": [7],
		"/error": [8],
		"/faq": [9],
		"/journeys": [~10],
		"/journeys/liquidity": [11],
		"/journeys/s2/chillblazers": [12],
		"/journeys/s2/thrillblazers": [13],
		"/journeys/s3/chillblazers": [14],
		"/journeys/s3/thrillblazers": [15],
		"/leaderboard": [~16],
		"/leaderboard/s1/bridge": [17],
		"/leaderboard/s1/dapp": [18],
		"/leaderboard/s1/defi": [19],
		"/leaderboard/s1/gaming": [20],
		"/leaderboard/s1/user": [21],
		"/leaderboard/s2/dapp": [22],
		"/leaderboard/s2/gaming": [23],
		"/leaderboard/s2/user": [24],
		"/leaderboard/s3/dapp": [25],
		"/leaderboard/s3/user": [26],
		"/pinkify": [27],
		"/pinkify/[slug]": [28],
		"/profile": [29],
		"/profile/[address]": [30]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';